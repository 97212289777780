.modal-content 
{
    position: relative;
    display: flex;
    flex-direction: column;
    width: 600px;
    pointer-events: auto;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 0.3rem;
    outline: 0;
    bottom: 150px;
    left: 160px;
}

.modal-dialog .modal-body {
    padding: 25px 15px;
}

.modal 
{
   overflow: unset;
}