/* Tabs */
.Tabs
{
    border-bottom: none;
    justify-content: center;
}

/* Tab */
.nav-tabs .nav-link 
{
    border: 0;
    font-size: 16px;
    margin: 0;
    transition: all 0.3s ease 0s;
    color: #555;
    padding-top: 30px;
    font-weight: 600;
    font-family: 'Poppins', sans-serif;
}

.nav-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link:hover, .nav-tabs .nav-item.show .nav-link
{
    color: #854fff;
    background-color: #fff;
    border: none;
}

.nav-tabs .nav-link:after {
    display: none;
}

p.price
{
    font-size: 18px;
    font-weight: 600;
    font-family: 'Poppins', sans-serif;
}

p.price span,
h3.store_name span
{
    margin-right: 5px;
}

.modal-content{
    top:0;
}

.store_name
{
    font-size: 16px;
    font-weight: 600;
    color: #555;
}

/* Card_Image */
.card
{
    border: none;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 3px 10px 0 rgba(0, 0, 0, 0.3);
}

.Card_Image
{
    height: 250px;
}

.card-body
{
    padding: 0.5rem;
}

.CartButton
{
    background: #a749ff;
    border: #a749ff;
    border-radius: 0;
    transition: all 0.5s ease-in-out;
}

.CartButton:hover
{
    background-color: #854fff;
}