/* Body */
.Body
{
    background: rgba(219, 215, 215, 0.1);
}

/* Row */
.Row
{
    width: 100%;
    margin: unset;
    padding-top: 2%;
    padding-bottom: 2%;
}

/* Column */
.Column
{
    padding: 25px;
}

/* Border */
.Border
{
    margin-bottom: 5%;
    background-color: #fff;
    border: 1px solid lightgrey;
}

/* Heading */
.Heading
{
    color: grey;
    font-size: 20px;
}

/* Padding */
.Padding
{
    padding: 15px;
}

/* Label */
.Label
{
    width: 40%;
    font-weight: 600;
}

/* LabelSpan */
.LabelSpan
{
    color: red;
}

/* Select1 */
.Select1
{
    width: 50%;
    height: 50px;
    border: unset;
    border-radius: 5px;
    background-color: #eeeeee;
    padding: 0.375rem 1.75rem 0.375rem 0.75rem;
}

/* Select2 */
.Select2
{
    width: 50%;
    height: 50px;
    border: unset;
    border-radius: 5px;
    background-color: #dddddd;
    padding: 0.375rem 1.75rem 0.375rem 0.75rem;
}

/* Input */
.Input
{
    width: 50%;
    height: 50px;
    border-radius: 5px;
    border: 1px solid #dddddd;
    background-color: #eeeeee;
    padding: 0.375rem 1.75rem 0.375rem 0.75rem;
}

/* Input2 */
.Input2
{
    width: 50%;
    height: 50px;
    border-radius: 5px;
    border: 1px solid #dddddd;
    padding: 0.375rem 1.75rem 0.375rem 0.75rem;
}

/* RadioInput */
.RadioInput
{
    margin-left: 2%;
    height: 15px;
    width: 15px;
}

/* FloatRight */
.FloatRight
{
    float: right;
    width: 80%;
}

/* RemoveButton */
.RemoveButton
{
    margin-right: 2%;
    width: 20%;
}

/* RemoveIcon */
.RemoveIcon
{
    margin-right: 5%;
}

/* DownloadButton */
.DownloadButton
{
    margin-right: 2%;
}

/* AddButton */
.AddButton
{
    width: 20%;
}

/* AddIcon */
.AddIcon
{
    margin-right: 5%;
    width: 20%;
}

/* BoxShadow */
.BoxShadow
{
    padding: 4px;
    box-shadow: '0 4px 8px 0 rgba(0,0,0,0.9), 0 6px 20px 0 rgba(0,0,0,0.19)';
}

/* Table */
.Table
{
    margin-top: 2%;
}

/* TableRow */
.TableRow
{
    background: #eeeeee;
}

/* CheckBox */
.CheckBox
{
    height: 15px;
    width: 15px;
    margin-right: 5%;
}

/* Row2 */
.Row2
{
    width: 100%;
    margin: unset;
    padding: 15px;
    border: 1px solid lightgrey;
}

/* TextCenter */
.TextCenter
{
    text-align: center;
}

/* BoxShadow2 */
.BoxShadow2
{
    margin-top: 5%;
    margin-bottom: 5%;
    box-shadow: '0 4px 8px 0 rgba(0,0,0,0.5), 0 6px 20px 0 rgba(0,0,0,0.19)';
}

/* InputWidth40 */
.InputWidth40
{
    width: 40%;
}

/* FloatRight2 */
.FloatRight2
{
    float: right;
    width: 90%;
}

/* MarginTop5 */
.MarginTop5
{
    margin-top: 5%;
}

/* MarginRight2 */
.MarginRight2
{
    margin-right: 2%;
}

/* MarginLeft2 */
.MarginLeft2
{
    margin-Left: 2%;
}

/* BoxShadow3 */
.BoxShadow3
{
    margin-top: 2%;
    margin-bottom: 2%;
    box-shadow: '0 4px 8px 0 rgba(0,0,0,0.5), 0 6px 20px 0 rgba(0,0,0,0.19)';
}

/* Paddig20 */
.Paddig20
{
    padding: 20px;
}

/* TableColumn */
.TableColumn
{
    width: 70%;
    height: 50px;
    border-radius: 5px;
    border: 1px solid #DDDDDD;
    margin-right: 2%;
}

/* MarginBottom3 */
.MarginBottom3
{
    margin-bottom: 3%;
}

/* OrangeBorder */
.OrangeBorder
{
    margin-top: 2%;
    margin-bottom: 5%;
    padding: 10px;
    border-top: 10px solid orange;
}

/* CheckBox2 */
.CheckBox2
{
    height: 15px;
    width: 15px;
    float: left;
    margin-top: 2px;
}

/* Text */
.Text
{
    margin-left: 2%;
}